var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('k-crud-layout',{attrs:{"search":_vm.searchQuery,"filters":_vm.filters},on:{"update:search":function($event){_vm.searchQuery=$event},"update:filters":function($event){_vm.filters=$event}},scopedSlots:_vm._u([{key:"header",fn:function(){return [_vm._v(_vm._s(_vm.$t('user.pageTitle')))]},proxy:true},{key:"flow-actions",fn:function(){return [_c('v-btn',{attrs:{"color":"primary","depressed":"","tile":""},on:{"click":_vm.handleOpenCreateDialog}},[_vm._v(" "+_vm._s(_vm.$t('user.create'))+" ")]),_c('UserForm',{attrs:{"request":_vm.createRequest,"title":_vm.$t('user.create'),"values":_vm.createFormValues},on:{"change":function($event){return _vm.$refs.table.reload()}},model:{value:(_vm.createDialog),callback:function ($$v) {_vm.createDialog=$$v},expression:"createDialog"}})]},proxy:true},{key:"filters",fn:function(ref){
var attrs = ref.attrs;
var on = ref.on;
return [_c('UsersFilter',_vm._g(_vm._b({},'UsersFilter',attrs,false),on))]}},{key:"view.list",fn:function(){return [_c('k-crud-table',{ref:"table",attrs:{"headers":_vm.crudHeaders,"index-request":_vm.indexRequest,"search":_vm.searchQuery,"params":_vm.filters,"language-prefix":"user.fields","resource":"user"},on:{"click:edit":_vm.openUpdate,"click:row":_vm.openUpdate},scopedSlots:_vm._u([{key:"item.isActive",fn:function(ref){
var isActive = ref.item.isActive;
return [(isActive)?_c('v-icon',[_vm._v("$enabled")]):_vm._e(),(!isActive)?_c('v-icon',[_vm._v("$disabled")]):_vm._e()]}},{key:"item.isAdmin",fn:function(ref){
var isAdmin = ref.item.isAdmin;
return [(isAdmin)?_c('v-icon',[_vm._v("$enabled")]):_vm._e(),(!isAdmin)?_c('v-icon',[_vm._v("$disabled")]):_vm._e()]}}])})]},proxy:true}])}),_c('UserForm',{attrs:{"request":_vm.updateRequest,"title":_vm.$t('user.update'),"values":_vm.updateFormValues,"is-update-form":""},on:{"change":function($event){return _vm.$refs.table.reload()}},model:{value:(_vm.updateDialog),callback:function ($$v) {_vm.updateDialog=$$v},expression:"updateDialog"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }